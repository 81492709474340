import * as React from 'react'
import Layout from '../layout'
import ProfilePage from '../pagesComponents/profile'

const Profile = () => {
	return (
		<Layout title="Edit Profile">
			<ProfilePage />
		</Layout>
	)
}

export default Profile
