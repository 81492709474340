import * as React from 'react'
import BasePage from '../components/basePage'
import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Switch,
	Text,
	useToast
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import Toast from '../components/toast'
import Input from '../components/input'
import { useState } from 'react'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { updateProfile } from '../services/user'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, date } from 'yup'
import CalendarSVG from '../icons/calendar.svg'
import ClearSVG from '../icons/clear.svg'
import DatePicker from 'react-date-picker'
import { errorToastOptions } from '../helpers'
import { useAuth } from '../hooks/useAuth'
import eventTracking from '../services/eventTracking'

const ProfilePage = () => {
	const { profile, logout, refreshProfile } = useAuth()
	const { email, name, surname, nickname, birth, receiveNews } = profile || {}
	const [subscribed, setSubscribe] = useState(receiveNews || false)
	const toast = useToast()
	const resolver = yupResolver(
		object()
			.shape({
				email: string().trim().email().required(),
				name: string().trim().required().min(2),
				surname: string().trim().required().min(2),
				nickname: string()
					.notBadWords()
					.trim()
					.required('Nickname must be set as appears on leaderboards')
					.min(2)
					.matches(/^\S*$/, 'The nickname can not contain spaces'),
				birth: date()
					.nullable()
					.notRequired()
					.max(new Date())
					.transform((curr, orig) => (orig === '' ? null : curr))
					.typeError('Invalid Date')
			})
			.required()
	)
	const {
		watch,
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onChange',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false,
		defaultValues: {
			email,
			name,
			surname,
			nickname,
			birth: birth ? moment(birth).format('YYYY-MM-DD') : null
		}
	})
	const watchEmail = watch('email')
	const watchName = watch('name')
	const watchSurname = watch('surname')
	const watchNickname = watch('nickname')
	const watchBirth = watch('birth')
	const isValid =
		!!watchEmail &&
		!!watchName &&
		!!watchSurname &&
		!!watchNickname &&
		!!watchNickname

	const mutation = useMutation(updateProfile, {
		onSuccess: async (data) => {
			await refreshProfile()
			toast({ render: () => <Toast text="Changes Saved" /> })
			eventTracking('change_profile', {
				user_id: profile?.id
			})

			if (!!subscribed) {
				eventTracking('OK_news', {
					user_id: profile?.id
				})
			}
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const onSubmit = async () => {
		const data = getValues()
		if (!data?.email || !data?.name || !data?.surname || !data?.nickname)
			return
		const mData = {
			email: data.email,
			name: data.name,
			surname: data.surname,
			nickname: data.nickname,
			receiveNews: subscribed
		}
		mData.birth = !!data.birth
			? moment(data.birth).format('YYYY-MM-DD')
			: null
		mutation.mutate(mData)
	}

	const handleLogout = async () => {
		await logout()
		await navigate('/login')
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				theme="dark"
				backBtn={'/'}
				largeTitle={
					<>
						Edit <br /> Profile
					</>
				}
			>
				<Input
					name="email"
					type="email"
					variant="filled"
					placeholder="Your email"
					mt="40px"
					register={register}
					errors={errors}
					disabled
				/>
				<Input
					name="name"
					type="text"
					variant="filled"
					placeholder="Your first name"
					mt="20px"
					register={register}
					errors={errors}
				/>
				<Input
					name="surname"
					type="text"
					variant="filled"
					placeholder="Your last name"
					mt="20px"
					register={register}
					errors={errors}
				/>
				<Input
					name="nickname"
					type="text"
					variant="filled"
					placeholder="Your nickname"
					mt="20px"
					register={register}
					errors={errors}
				/>
				<Flex position="relative">
					<Flex flex="1" h="60px" bg="darkGray" mt="20px">
						<Flex
							cursor="pointer"
							alignItems="center"
							position="absolute"
							top="20px"
							bottom="0"
							left="0"
							right="0"
							px="22px"
						>
							<Text
								variant={!!watchBirth ? 'date' : 'placeholder'}
								zIndex="2"
								pointerEvents="none"
							>
								{!!watchBirth
									? moment(watchBirth).format('DD/MM/YYYY')
									: 'Date of Birth (Optional)'}
							</Text>
						</Flex>
						<DatePicker
							value={watchBirth}
							clearIcon={!!watchBirth ? <ClearSVG /> : null}
							calendarIcon={
								<CalendarSVG style={{ marginRight: 15 }} />
							}
							onChange={(value) => setValue('birth', value)}
						/>
					</Flex>
				</Flex>
				{!!errors?.birth && (
					<Flex justifyContent="center">
						<Text variant="error" as="span">
							{errors.birth.message}
						</Text>
					</Flex>
				)}

				<FormControl
					display="flex"
					alignItems="center"
					mt="24px"
					mb="28px"
				>
					<FormLabel flex="1" as="h5" ml="0" mr="25px" mb="0">
						I want to receive news, events and latest updates from
						Fairgame
					</FormLabel>
					<Switch
						size="lg"
						colorScheme="yellow"
						isChecked={subscribed}
						onChange={() => setSubscribe(!subscribed)}
					/>
				</FormControl>

				<Flex direction="column">
					<Button
						variant="primary"
						mb="20px"
						isLoading={mutation.isLoading}
						disabled={!isValid || mutation.isLoading}
						type="submit"
					>
						Save changes
					</Button>
					<Button variant="text" onClick={handleLogout}>
						Log out
					</Button>
				</Flex>
			</BasePage>
		</form>
	)
}

export default ProfilePage
