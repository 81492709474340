import * as React from 'react'
import { Flex, Container, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import CheckSVG from '../icons/check.svg'

const Toast = (props) => {
	const { text } = props
	return (
		<Container
			variant="toast"
			ml={{ lg: '380px', base: 'auto' }}
			mb={{ lg: '40px', base: '10px' }}
		>
			<StaticImage
				style={{
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0
				}}
				alt="Toast Background"
				src="../images/toast.png"
			/>
			<Flex
				direction="row"
				zIndex="4"
				position="absolute"
				alignItems="center"
				top="14px"
				bottom="14px"
				left="20px"
				right="16px"
			>
				<Flex flex="1" mr="7px">
					<Text variant="toast">{text}</Text>
				</Flex>
				<CheckSVG />
			</Flex>
		</Container>
	)
}

export default Toast
